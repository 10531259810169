import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counter';
import loginSlice from '../slices/loginSlice';
import menuReducer from '../slices/menuSlice'; // Import the new reducer
const store = configureStore({
  reducer: {
    counter: counterReducer,
    loginSlice: loginSlice,
    menu: menuReducer, // Add the new reducer to the store
  },
});

export default store; // Make sure to export store as default