import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from '../router/root';

function App() {
  return (
    <Router>
      <Suspense fallback={<div className="flex items-center justify-center h-screen">페이지 로딩중....</div>}>
        <Root />
      </Suspense>
    </Router>
  );
}

export default App;
