import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

const ClientPage = lazy(() => import("../pages/client/ClientPage"));
const TransactionHistoryPage = lazy(() => import("../pages/client/TransactionHistoryPage"));

const ClientRouter = () => (
    <Routes>
        <Route path="/" element={<TransactionHistoryPage />} />
        <Route path="register" element={<ClientPage />} />
        <Route path="transactionHistory" element={<TransactionHistoryPage />} />
    </Routes>
);

export default ClientRouter;
