import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { socialRefresh } from "../api/memberApi";
import { getCookie, setCookie, removeCookie } from "../util/cookieUtil";

const initState = {
    id: '',
    name: '',
    email: '',
    nickname: '',
    gender: '',
    birthyear: '',
    birthday: '',
    repIcon: '',
    baseAuthRole: '',
    provider: '',
    registrationNumber: '',
    businessName: '',
    businessAddress: '',
    transactionMemo: '',
    classificationCode: '',
    accessToken: getCookie('accessToken') || '',
    refreshToken: getCookie('refreshToken') || ''
};

export const loginPostAsync = createAsyncThunk('loginPostAsync', async (param) => {
    const response = await socialRefresh(param);
    if (response.error) {
        throw new Error(response.error);
    }
    return response;
});

export const loadMemberCookie = () => {  //쿠키에서 로그인 정보 로딩 
    return {
        ...initState,
        id: getCookie("id"),
        name: getCookie("name"),
        email: getCookie("email"),
        nickname: getCookie("nickname"),
        gender: getCookie("gender"),
        birthyear: getCookie("birthyear"),
        birthday: getCookie("birthday"),
        repIcon: getCookie("repIcon"),
        baseAuthRole: getCookie("baseAuthRole"),
        provider: getCookie("provider"),
        registrationNumber: getCookie("registrationNumber"),
        businessName: getCookie("businessName"),
        businessAddress: getCookie("businessAddress"),
        transactionMemo: getCookie("transactionMemo"),
        classificationCode: getCookie("classificationCode"),
        accessToken: getCookie('accessToken'),
        refreshToken: getCookie('refreshToken')
    };
};

export const createLoginAction = (params) => {
    const payload = Object.fromEntries(params.entries());
    return { type: 'LoginSlice/login', payload };
};

const loginSlice = createSlice({
    name: 'LoginSlice',
    initialState: initState,
    reducers: {
        login: (state, action) => {
            console.log("login.....");
            const payload = action.payload;

            setCookie("id", payload.id, 1);
            setCookie("name", payload.name, 1);
            setCookie("accessToken", payload.accessToken, 1);
            setCookie("refreshToken", payload.refreshToken, 30);
            setCookie("nickname", payload.nickname, 1);
            setCookie("email", payload.email, 1);
            setCookie("baseAuthRole", payload.baseAuthRole, 1);
            setCookie("provider", payload.provider, 1);
            setCookie("gender", payload.gender, 1);
            setCookie("birthyear", payload.birthyear, 1);
            setCookie("birthday", payload.birthday, 1);
            setCookie("repIcon", payload.repIcon, 1);
            setCookie("registrationNumber", payload.registrationNumber, 1);
            setCookie("businessName", payload.businessName, 1);
            setCookie("businessAddress", payload.businessAddress, 1);
            setCookie("transactionMemo", payload.transactionMemo, 1);
            setCookie("classificationCode", payload.classificationCode, 1);

            return {
                ...state,
                id: payload.id,
                name: payload.name,
                email: payload.email,
                nickname: payload.nickname,
                gender: payload.gender,
                birthyear: payload.birthyear,
                birthday: payload.birthday,
                repIcon: payload.repIcon,
                baseAuthRole: payload.baseAuthRole,
                provider: payload.provider,
                registrationNumber: payload.registrationNumber,
                businessName: payload.businessName,
                businessAddress: payload.businessAddress,
                transactionMemo: payload.transactionMemo,
                classificationCode: payload.classificationCode,
                accessToken: payload.accessToken,
                refreshToken: payload.refreshToken
            };
        },
        logout: (state) => {
            console.log("logout....");

            removeCookie("accessToken");
            removeCookie("refreshToken");
            removeCookie("email");
            removeCookie("nickname");
            removeCookie("baseAuthRole");
            removeCookie("provider");
            removeCookie("id");
            removeCookie("name");
            removeCookie("gender");
            removeCookie("birthyear");
            removeCookie("birthday");
            removeCookie("repIcon");
            removeCookie("registrationNumber");
            removeCookie("businessName");
            removeCookie("businessAddress");
            removeCookie("transactionMemo");
            removeCookie("classificationCode");

            return { ...initState };
        },
        loadMemberFromCookie: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginPostAsync.fulfilled, (state, action) => {
            console.log("fulfilled");
            const payload = action.payload;

            setCookie("accessToken", payload.accessToken, 1);
            setCookie("refreshToken", payload.refreshToken, 30);
            setCookie("nickname", payload.nickname, 1);
            setCookie("email", payload.email, 1);
            setCookie("baseAuthRole", payload.baseAuthRole, 1);
            setCookie("provider", payload.provider, 1);
            setCookie("id", payload.id, 1);
            setCookie("name", payload.name, 1);
            setCookie("gender", payload.gender, 1);
            setCookie("birthyear", payload.birthyear, 1);
            setCookie("birthday", payload.birthday, 1);
            setCookie("repIcon", payload.repIcon, 1);
            setCookie("registrationNumber", payload.registrationNumber, 1);
            setCookie("businessName", payload.businessName, 1);
            setCookie("businessAddress", payload.businessAddress, 1);
            setCookie("transactionMemo", payload.transactionMemo, 1);
            setCookie("classificationCode", payload.classificationCode, 1);

            return {
                ...state,
                email: payload.email,
                nickname: payload.nickname,
                baseAuthRole: payload.baseAuthRole,
                provider: payload.provider,
                accessToken: payload.accessToken,
                refreshToken: payload.refreshToken,
                id: payload.id,
                name: payload.name,
                gender: payload.gender,
                birthyear: payload.birthyear,
                birthday: payload.birthday,
                repIcon: payload.repIcon,
                registrationNumber: payload.registrationNumber,
                businessName: payload.businessName,
                businessAddress: payload.businessAddress,
                transactionMemo: payload.transactionMemo,
                classificationCode: payload.classificationCode
            };
        })
            .addCase(loginPostAsync.pending, () => {
                console.log("pending");
            })
            .addCase(loginPostAsync.rejected, () => {
                console.log("rejected");
            })
    }
});

export const { login, logout, loadMemberFromCookie } = loginSlice.actions;
export default loginSlice.reducer;
