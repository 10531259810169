import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    activeMenu: 'main',
    menu: [
        { id: uuidv4(), name: "홈", url: "main", subMenu: [] },
        {
            id: uuidv4(),
            name: "거래처",
            url: "client",
            subMenu: [
                { id: uuidv4(), name: "거래처 등록", url: "client/register" },
                { id: uuidv4(), name: "거래처 거래내역", url: "client/transactionHistory" }
            ]
        },
        {
            id: uuidv4(),
            name: "서비스",
            url: "services",
            subMenu: [
                { id: uuidv4(), name: "경매낙찰 내역", url: "services/auctionResults" },
                { id: uuidv4(), name: "제품코드 관리", url: "services/productManagement" },
                { id: uuidv4(), name: "제고 관리", url: "services/inventory" }
            ]
        },
        { id: uuidv4(), name: "통계", url: "contactus", subMenu: [] },
        {
            id: uuidv4(),
            name: "커뮤니티",
            url: "community",
            subMenu: [
                { id: uuidv4(), name: "커뮤니티1", url: "community/1" },
                { id: uuidv4(), name: "커뮤니티2", url: "community/2" }
            ]
        }
    ],
};

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setActiveMenu: (state, action) => {
            state.activeMenu = action.payload;
        },
    },
});

export const { setActiveMenu } = menuSlice.actions;

export default menuSlice.reducer;
