import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

const AuctionResultsPage = lazy(() => import("../pages/services/AuctionResultsPage"))
const ProductManagement = lazy(() => import("../pages/services/ProductManagementPage"))
const InventoryPage = lazy(() => import("../pages/services/InventoryPage"))

const ServicesRouter = () => (
    <Routes>
        <Route path="/" element={<AuctionResultsPage />} />
        <Route path="auctionResults" element={<AuctionResultsPage />} />
        <Route path="productManagement" element={<ProductManagement />} />
        <Route path="inventory" element={<InventoryPage />} />
    </Routes>
);

export default ServicesRouter;
