import React, { lazy } from 'react';
import { Routes, Route } from "react-router-dom";
import MemberRouter from "./memberRouter";
import ClientRouter from "./clientRouter";
import ServicesRouter from "./servicesRouter";

const Main = lazy(() => import("../pages/MainPage"));
const Community = lazy(() => import("../pages/community/CommunityPage"));

const Root = () => (
    <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/main" element={<Main />} />
        <Route path="/client/*" element={<ClientRouter />} />
        <Route path="/services/*" element={<ServicesRouter />} />
        <Route path="/community" element={<Community />} />
        <Route path="/member/*" element={<MemberRouter />} />
    </Routes>
);

export default Root;
