import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

const Login = lazy(() => import("../pages/member/LoginPage"))
const SocialLoginRedirect = lazy(() => import("../pages/member/SocialLoginPage"))
const MemberModify = lazy(() => import("../pages/member/ModifyPage"))

const MemberRouter = () => (
    <Routes>
        <Route path="login" element={<Login />} />
        <Route path="loginInfo" element={<SocialLoginRedirect />} />
        <Route path="modify" element={<MemberModify />} />
    </Routes>
);

export default MemberRouter;
